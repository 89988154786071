import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0017 from '../../../components/molecules/columnDetailInner0017'
import UsefulFunctionLayout018 from '../../../components/molecules/usefulFunctionLayout018'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0017 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '法定休日とは？法定外休日との違いなどを解説',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="法定休日とは？法定外休日との違いなど、休日に関する知識を公開"
        description="法定休日とは、国が労働基準法という法律で定めた「休日」を指し、事業規模や業種を問わず、会社側も法定休日を遵守する形で会社の休日を設定する必要があります。"
        ogUrl="https://kintaicloud.jp/column/details/C0017/"
        ogType="article"
        ogTitle="法定休日とは？法定外休日との違いなど、休日に関する知識を公開"
        ogDescription="法定休日とは、国が労働基準法という法律で定めた「休日」を指し、事業規模や業種を問わず、会社側も法定休日を遵守する形で会社の休日を設定する必要があります。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0017.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0017 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout018 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0017

export const pageQuery = graphql`
  query C0017 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
