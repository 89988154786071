import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0017 = () => (
  <Wrapper>
    <ColumnH1 label="法定休日とは？法定外休日との違いなど、休日に関する知識を公開" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      <img src="/images/column/details/c0017.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        会社にとって、そして会社で働く社員にとって、最も重要な雇用に関する内容に
        <span>「休日」</span>
        が挙げられます。社員には一定の頻度で休日を与えなければならない、という事を把握している経営者は多いかと思いますが、実際にはどのような頻度で、何日の休日を与えなければならないのか、という詳細を明確に把握しているか否か、と問われれば不安になる場合も少なくないのではないでしょうか。
        <br />
        <br />
        今回は、そもそも法定休日とはどのような内容か、法定外休日などとの違いは何か、などの基本的な内容について、順を追って解説をしていきますので、参考にして下さい。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">「休日」とは</a>
          </li>
          <li>
            <a href="#toc2">「法定休日」とは</a>
          </li>
          <li>
            <a href="#toc3">「法定休日」の具体的な日数</a>
          </li>
          <li>
            <a href="#toc4">休日のカウント方法とは</a>
          </li>
          <li>
            <a href="#toc5">「法定外休日」とは</a>
          </li>
          <li>
            <a href="#toc6">「法定休日」における罰則とは</a>
          </li>
          <li>
            <a href="#toc7">まとめ</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>「休日」とは</h1>
      </div>
      <p>
        そもそも、「休日」とはどのような日のことを言うのでしょうか。
        <br />
        休日とは、社員が労働をする義務がない日をいいます。社員側がさまざまな理由で仕事をすることができず、「休みます」と申し出た日ではなく、そもそも
        <span>仕事をする必要のない日</span>の事です。
        <br />
        考え方としては、「今日は日曜日だから、仕事をする必要がなく休みだ」ではなく、「仕事をする必要のない日曜日だから休む」という意味合いとなります。あくまでも、
        <span>会社側が仕事の義務を免れる日として定めた日</span>
        が休日であることを覚えておきましょう。
      </p>
      <div id="toc2">
        <h1>「法定休日」とは</h1>
      </div>
      <p>
        法定休日は、<span>国が労働基準法という法律で定めた「休日」</span>
        をいいます。労働基準法は、雇用する社員が健康を損ねず、安心して働き続けてもらうために最低限保障しなければならない内容が定められている重要な法律です。したがって、会社側も法定休日を遵守する形で会社の休日を設定しなければなりません。
      </p>
      <div id="toc3">
        <h1>「法定休日」の具体的な日数</h1>
      </div>
      <p>
        法定休日の具体的な日数は、
        <span>原則1週間に1日以上、例外措置として4週間内に4日の休日</span>です。
        <br />
        「例外措置である4週間内に4日の休日」は、週休で休みを取らせることが難しい場合に取り入れることのできる
        <span>「変形休日制」</span>
        を活用した場合の休日数をいいます。この場合の4週間は、どの曜日で区切ったとしても、必ず休日が4日以上設定されていなければならないということではなく、特定の4週間内に、4日以上の休日が定められていれば問題ないという事です。
        <br />
        <br />
        この方法を採用する場合、たとえば1～3週間の間は休日を付与せず、最後の4週間目に4日間の休日を付与することも理論上は可能となります。しかし、このような休日付与を行った場合、社員が健康に支障をきたし、会社側の健康安全に関する配慮義務違反として扱われる危険性や、法定労働時間を超える労働により、多額の割増賃金の支払いが求められる危険性があります。したがって、
        <span>極端な休日の付与は出来る限り避けるべき</span>と考えられます。
        <br />
        <br />
        なお、変形休日制を導入する場合は、就業規則でその内容を定め、必ず変形休日期間のはじまりとなる「起算日」を記載する必要があることもあわせて覚えておきましょう。
      </p>
      <Introduction003 />
      <div id="toc4">
        <h1>休日のカウント方法とは</h1>
      </div>
      <p>
        休日の付与単位は、<span>歴日数が基準</span>
        となります。つまり、真夜中の午前0時から、同じく真夜中の午後12時までの休みが休日の1単位です。なお、労働時間をカウントする際も同様に歴日数単位となります。
        <br />
        <br />
        ただし例外として、交替制の勤務などを採用している企業では、時間によって2暦日にまたがった就労時間となる場合があります。このようなケースでは、連続して24時間分の休業時間が保障されていれば、1単位の休日として扱うことが認められています。
        <br />
        つまり、社員がどのような働き方をする場合でも、最低でもまる1日分は休日として休息をさせる必要があるという事です。
      </p>
      <div id="toc5">
        <h1>「法定外休日」とは</h1>
      </div>
      <p>
        法定外休日とは、<span>法律で定められていない休日</span>のことです。
        <br />
        法定休日が、原則として1週間に1日以上と定められていることから、たとえば完全週休二日制を導入しており、土日をともに休日として扱う会社の場合は、毎週1日分の法定休日に加え、1日分の「法定外休日」を付与していることになります。また、土日祝日を休日と定めている会社の場合は、毎週1日分に加え、祝日分の法定外休日を付与しているといえます。
      </p>
      <div id="toc6">
        <h1>「法定休日」における罰則とは</h1>
      </div>
      <p>
        法律にそって法定休日を付与しなかった会社に対しては、労働基準法違反として6ヶ月以下の懲役または30万円以下の罰金刑に処せられる可能性があります。一方、法定外休日は法律で義務づけられた休日ではないため、付与しなくても罰則に問われることはありません。
        <br />
        <br />
        しかし、ここで覚えておかなければならないのが、法定休日と同時に企業が遵守しなければならない労働時間に関するルールの一つである
        <span>「法定労働時間」</span>の事です。
        <br />
        法定労働時間とは、社員の労働時間の上限であり、これを超えて労働をさせてはならないとルール付けされている時間のこと、具体的な数値は1日あたり8時間、1週間あたり40時間が原則です。
        <br />
        この法定労働時間を守りながら労働時間・労働日の設定をした場合、特段の理由がない限りは平日5日間×8時間＝週あたり40時間、という、平日にフルタイム勤務を設定する企業が多くみられます。
        <br />
        <br />
        つまり、
        <span>
          法定労働時間を遵守しながら労働日数を定めていくと、自動的に週あたり1日の法定外休日が発生する
        </span>
        、という仕組みになっているのです。なお、法定外休日を付与せずに法定労働時間・法定休日を遵守した労働時間・労働日の組み方としては、たとえば一日の労働時間を6.5時間とし、週に6日働かせる方法なども挙げられます。
      </p>
      <div id="toc7">
        <h1 className="read">まとめ</h1>
      </div>
      <p>
        法定休日と法定外休日は、法律による規制の有無という点で大きく異なる点についてお分かりいただけましたでしょうか。労働時間や休日の設定については、法律を守ることを前提として、会社の状況に応じた内容で定めていく必要がありますが、無理なスケジュールを組んでしまうと、働く社員側に大きな負担が生じる危険性もあります。
        <br />
        <br />
        無用な労使トラブルを避けるためにも、企業側・社員側それぞれの立場を考慮した労働日の組み方を心がける必要があります。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0017
